<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      signData: {},
    };
  },
  mounted() {
    // this.getSign();
  },
  methods: {
    getSign() {
      let url = "";
      let userAgent = navigator.userAgent;
      if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
        url = location.href;
      } else {
        url = window.location.href;
      }
      // alert(url);
      this.$post("/wxapi/user/get_signature", {
        url: encodeURIComponent(url),
      }).then((res) => {
        wx.config({
          debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。（测试记得关掉）
          appId: res.appId, // 必填，公众号的唯一标识
          timestamp: res.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.nonceStr, // 必填，生成签名的随机串
          signature: res.signature, // 必填，签名，见附录1
          jsApiList: ["getLocation"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });
        wx.checkJsApi({
          jsApiList: ["getLocation"], // 需要检测的JS接口列表，所有JS接口列表见附录2,
          success: function (res) {
            // 以键值对的形式返回，可用的api值true，不可用为false
            // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
            console.log("checkJsApi-------", res);
          },
        });
        wx.ready(function () {
          wx.getLocation({
            success: function (res) {
              var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
              var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
              console.log("纬度", latitude);
              console.log("经度", longitude);
            },
            cancel: function (res) {
              console.log(res);
              // alert("用户拒绝授权获取地理位置");
            },
          });
        });
        wx.error(function (res) {
          // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
          console.log("验证失败返回的信息:", res);
        });
      });
    },
  },
};
</script>
<style lang="scss">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html,
body {
  height: 100vh;
  overflow: auto;
}
img {
  max-width: 100%;
}
a {
  color: inherit;
}
</style>
