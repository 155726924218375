import Vue from "vue";
import Vuex from "vuex";
// import createPersistedstate from "vuex-persistedstate";

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    cartCount: 0,
    diff: 0,
  },
  mutations: {
    getCartCount(state, val) {
      state.cartCount = val;
    },
    changeCartCount(state, val) {
      state.cartCount = state.cartCount + val;
      state.diff = val;
    },
  },
});
export default store;
