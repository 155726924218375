import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "index",
  },
  {
    path: "/index",
    name: "index",
    redirect: "/index/user",
    component: () => import("@/views/user/index.vue"),
    children: [
      {
        path: "user",
        component: () => import("@/views/user/user.vue"),
      },
      {
        path: "balance",
        component: () => import("@/views/user/balance.vue"),
      },
      {
        path: "coupons",
        component: () => import("@/views/user/coupons/index.vue"),
        redirect: "/index/coupons/index",
        children: [
          {
            path: "index",
            component: () => import("@/views/user/coupons/coupons.vue"),
          },
          {
            path: "list",
            component: () => import("@/views/user/coupons/list.vue"),
          },
          {
            path: "detail",
            component: () => import("@/views/user/coupons/detail.vue"),
          },
        ],
      },
      {
        path: "info",
        component: () => import("@/views/user/info.vue"),
      },
      {
        path: "phone",
        component: () => import("@/views/user/phone.vue"),
      },
      {
        path: "consume",
        component: () => import("@/views/user/consume.vue"),
      },
      {
        path: "recharge",
        component: () => import("@/views/user/recharge.vue"),
      },
      {
        path: "editPswd",
        component: () => import("@/views/user/editPswd.vue"),
      },
      {
        path: "rechargeList",
        component: () => import("@/views/user/rechargeList.vue"),
      },
      {
        path: "points",
        component: () => import("@/views/user/points.vue"),
      },
      {
        path: "rule",
        component: () => import("@/views/user/rules.vue"),
      },
      {
        path: "order",
        component: () => import("@/views/user/order.vue"),
      },
      {
        path: "invoice",
        component: () => import("@/views/user/invoice.vue"),
      },
      {
        path: "stocks",
        component: () => import("@/views/user/stocks.vue"),
      },
    ],
  },
  {
    path: "/cardid",
    name: "cardid",
    component: () => import("@/views/card/index.vue"),
  },
  {
    path: "/cardid/:id",
    name: "cardid",
    component: () => import("@/views/card/index.vue"),
  },
  {
    path: "/goods",
    name: "goods",
    component: () => import("@/views/card/goods.vue"),
  },
  {
    path: "/card",
    name: "card",
    component: () => import("@/views/card/card.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("@/views/logout.vue"),
  },
  {
    path: "/phone",
    name: "phone",
    component: () => import("@/views/phone.vue"),
  },
  {
    path: "/default",
    name: "default",
    component: () => import("@/views/default.vue"),
  },
  {
    path: "/choose",
    name: "choose",
    component: () => import("@/views/choose.vue"),
  },
  {
    path: "/poster",
    name: "poster",
    redirect: "/poster/poster",
    component: () => import("@/views/poster/index.vue"),
    children: [
      {
        path: "poster",
        component: () => import("@/views/poster/poster.vue"),
      },
    ],
  },
  {
    path: "/mall",
    name: "mall",
    redirect: "/mall/index",
    component: () => import("@/views/mall/index.vue"),
    children: [
      {
        path: "index",
        component: () => import("@/views/mall/index/index.vue"),
      },
      {
        path: "goods",
        redirect: "goods/index",
        component: () => import("@/views/mall/goods/index.vue"),
        children: [
          {
            path: "index",
            component: () => import("@/views/mall/goods/list.vue"),
          },
          {
            path: "detail",
            component: () => import("@/views/mall/goods/detail.vue"),
          },
        ],
      },
      {
        path: "cart",
        redirect: "cart/index",
        component: () => import("@/views/mall/cart/index.vue"),
        children: [
          {
            path: "index",
            component: () => import("@/views/mall/cart/list.vue"),
          },
          {
            path: "detail",
            component: () => import("@/views/mall/goods/detail.vue"),
          },
        ],
      },
      {
        path: "order",
        redirect: "index",
        component: () => import("@/views/mall/order/index.vue"),
        children: [
          {
            path: "index",
            component: () => import("@/views/mall/order/list.vue"),
          },
          {
            path: "order",
            component: () => import("@/views/mall/order/order.vue"),
          },
          {
            path: "detail",
            component: () => import("@/views/mall/order/detail.vue"),
          },
        ],
      },
      {
        path: "address",
        redirect: "index",
        component: () => import("@/views/mall/address/index.vue"),
        children: [
          {
            path: "index",
            component: () => import("@/views/mall/address/list.vue"),
          },
          {
            path: "form",
            component: () => import("@/views/mall/address/form.vue"),
          },
        ],
      },
      {
        path: "cardRecharge",
        name: "cardRecharge",
        component: () => import("@/views/mall/cardRecharge/index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
