import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { $get, $post, $upload } from "@/utils/request";

import Vant from "vant";
import "vant/lib/index.css";

Vue.use(Vant);

Vue.config.productionTip = false;

Vue.prototype.$get = $get;
Vue.prototype.$post = $post;
Vue.prototype.$upload = $upload;
//引入商城api
import { $mall } from "@/api/mall";
Vue.prototype.$mall = $mall;

import navBar from "@/components/navbar.vue";
Vue.component("navBar", navBar);

import vueClipboard from "vue-clipboard2";
Vue.use(vueClipboard);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
