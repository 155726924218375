import { $post } from "@/utils/mall";
const $mall = {
  // 获取在线商城公共配置
  getBanner: (data) => $post("/wxapi/onlineshopbanner/get_banner_list", data),
  // 获取在线商城公共配置
  getConfig: () => $post("/wxapi/Onlineshop/get_online_shop_config"),
  // 获取可切换店铺列表
  getShop: () => $post("/wxapi/Onlineshop/get_online_shop_list"),
  // 获取当前选中店铺信息
  getShopInfo: () => $post("/wxapi/Onlineshop/get_online_shop_info"),
  // 商品分类列表
  get_good_class_list: () =>
    $post("/wxapi/Onlineshopgoodclass/get_good_class_list"),
  // 获取分类商品列表
  get_good_list: (data) => $post("/wxapi/good/get_good_list", data),

  // 获取热销产品
  get_hot_good: (data) => $post("/wxapi/good/get_hot_good", data),

  // 获取分类商品
  get_good: (data) => $post("/wxapi/good/get_good_class_list", data),
  // 获取商品详情
  get_good_info: (data) => $post("/wxapi/good/get_good_info", data),
  // 获取购物车数量
  get_car_number: (data) => $post("/wxapi/Onlineshopcar/get_car_number", data),
  // 获取购物车列表
  get_car_list: (data) => $post("/wxapi/Onlineshopcar/get_car_list", data),
  // 购物车数量加减
  change_car_number: (data) =>
    $post("/wxapi/Onlineshopcar/change_car_number", data),
  // 购物车删除
  del_car: (data) => $post("/wxapi/Onlineshopcar/del_car", data),
  // 获取收货地址列表
  get_address_list: (data) =>
    $post("/wxapi/Useraddress/get_address_list", data),
  // 添加收货地址
  add_address: (data) => $post("/wxapi/Useraddress/add_address", data),
  // 修改收货地址
  edit_address: (data) => $post("/wxapi/Useraddress/edit_address", data),
  // 删除收货地址
  del_address: (data) => $post("/wxapi/Useraddress/del_address", data),
  // 获取收货地址详情
  get_address_info: (data) =>
    $post("/wxapi/Useraddress/get_address_info", data),
  // 获取默认地址
  get_default_address: (data) =>
    $post("/wxapi/Useraddress/get_default_address", data),
  // 待结算数据
  get_settlement: (data) =>
    $post("/wxapi/Onlineshoporder/get_settlement", data),
  // 生成订单
  add_order: (data) => $post("/wxapi/Onlineshoporder/add_order", data),
  // 获取订单列表
  get_order_list: (data) =>
    $post("/wxapi/Onlineshoporder/get_order_list", data),
  // 获取订单详情
  get_order_info: (data) =>
    $post("/wxapi/Onlineshoporder/get_order_info", data),
  // 确认收货
  confirm_receipt: (data) =>
    $post("/wxapi/Onlineshoporder/confirm_receipt", data),
  // 取消订单
  cancel_order: (data) => $post("/wxapi/Onlineshoporder/cancel_order", data),
  // 获取储值卡余额
  get_vipcard_info: (data) => $post("/wxapi/Vipcard/get_vipcard_info", data),
  // 在线商城余额充值
  recharge_balance: (data) => $post("/wxapi/Vipcard/recharge_balance", data),
  // 获取支付详情
  get_pay_info: (data) => $post("/wxapi/Onlineshoporderpay/get_pay_info", data),
  // 在线商城支付
  pay_money: (data) => $post("/wxapi/Onlineshoporderpay/pay_money", data),
};
export { $mall };
